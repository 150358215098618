import { Orders } from "redux/constants/Orders";

const initState = {
  data: [],
  loading: false,
};

const orders = (state = initState, action) => {
  switch (action.type) {
    case Orders.LOADING_ORDERS_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Orders.GET_ORDERS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default orders;
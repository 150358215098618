import { Groups } from "redux/constants/Groups";

const initState = {
  data: [],
  loading: false,
};

const groups = (state = initState, action) => {
  switch (action.type) {
    case Groups.LOADING_GROUPS_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Groups.GET_GROUPS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default groups;
import { Accounts } from "redux/constants/Accounts";

const initState = {
  accounts: [],
  loading: false,
};

const accounts = (state = initState, action) => {
  switch (action.type) {
    case Accounts.ACCOUNTS_DATA.GET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          data: action.payload,
        },
      };
    case Accounts.ACCOUNTS_DATA.LOADING_ACCOUNTS:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          loading: action.payload,
        },
      };
    case Accounts.ACCOUNTS_DATA.GET_ACCOUNT_BALANCE:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          selectedAccount: {
            ...state?.accounts.selectedAccount,
            balance: action.payload,
          },
        },
      };
    case Accounts.ACCOUNTS_DATA.GET_TOPUPS_TRANSFERS:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          selectedAccount: {
            ...state?.accounts.selectedAccount,
            topups: action.payload.topups,
            transfers: action.payload.transfers,
            currency: action.payload.currency,
          },
        },
      };
    case Accounts.ACCOUNTS_DATA.LOADING_ACCOUNT_BLANCE:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          loadingBalance: action.payload,
        },
      };
    case Accounts.ACCOUNTS_DATA.LOADING_TOPUPS_TRANSFERS:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          loadingTopupsTransfers: action.payload,
        },
      };
    case Accounts.ACCOUNTS_DATA.LOADING_TOPUPS_VS_TRANSFERS:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          loadingTopupsVsTransfers: action.payload,
        },
      };
    case Accounts.ACCOUNTS_DATA.GET_TOPUPS_VS_TRANSFERS:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          selectedAccount: {
            ...state?.accounts.selectedAccount,
            topupsVsTransfers: action.payload,
          },
        },
      };
    case Accounts.ACCOUNTS_DATA.SET_SELECTED_ACCOUNT:
      return {
        ...state,
        accounts: {
          ...state?.accounts,
          selectedAccount: action.payload,
        },
      };
    case Accounts.SUMMARY_STATS.GET_STATS:
      return {
        ...state,
        stats: {
          data: action.payload,
          loading: state?.stats?.loading,
        },
      };
    case Accounts.SUMMARY_STATS.LOADING_STATS:
      return {
        ...state,
        stats: {
          data: state?.stats?.data,
          loading: action.payload,
        },
      };
    case Accounts.TRANSACTION_HISOTRY.GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: {
          data: action.payload,
          loading: state?.transactions?.loading,
        },
      };
    case Accounts.TRANSACTION_HISOTRY.LOADING_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: {
          data: state?.transactions?.data,
          loading: action.payload,
        },
      };
    case Accounts.LOADING_SUBACCOUNTS_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Accounts.GET_SUBACCOUNTS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default accounts;

import { Services } from "redux/constants/Services";

const initState = {
  data: [],
  loading: false,
};

const services = (state = initState, action) => {
    switch (action.type) {
      case Services.LOADING_SERVICES_LIST:
        return {
          ...state,
          loading: action.payload,
        };
      case Services.GET_SERVICES_LIST:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default services;
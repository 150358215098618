import { Users } from "redux/constants/Users";

const initState = {
  data: [],
  loading: false,
};

const users = (state = initState, action) => {
  switch (action.type) {
    case Users.LOADING_USERS_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Users.GET_USERS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default users;
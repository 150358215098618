import { Statement } from "redux/constants/StatementList";

const initState = {
    data: [],
    loading: false,
  };
  
  const statement = (state = initState, action) => {
    switch (action.type) {
      case Statement.LOADING_STATEMENT_LIST:
        return {
          ...state,
          loading: action.payload,
        };
      case Statement.GET_STATEMENT_LIST:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default statement;
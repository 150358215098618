import { Bills } from "redux/constants/Bills";

const initState = {
  data: [],
  loading: false,
};

const bills = (state = initState, action) => {
  switch (action.type) {
    case Bills.LOADING_BILLS_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Bills.GET_BILLS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default bills;
import { baseUrl } from 'constants/ApiConstant';
import * as Request from 'utils/Request';

const authBaseUrl = `${baseUrl}/auth`;

export const getRecentActivityAPI = async (body: Object) => {
  try {
    const url = `${baseUrl}/activity`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const getRecentLoginsAPI = async (body: Object) => {
  try {
    const url = `${authBaseUrl}/logins`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const changePasswordAPI = async (body: Object) => {
  try {
    const url = `${authBaseUrl}/changePassword`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
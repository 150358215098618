const initState = {
  menu: [],
  loading: false,
}

const menu = (state = initState, action) => {
	switch (action.type) {
		case "GET_MENU":
			return {
				...state,
				 menu: action.payload
			}
      default:
        return state;
    }
  }

  export default menu



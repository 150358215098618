export const Transfers = {
  BULK: {
    // SET_MAPPINGS: "SET_MAPPINGS",
    // GET_MAPPINGS: "GET_MAPPINGS",
    // GET_MAPPINGS_RESPONSE: "GET_MAPPINGS_RESPONSE",
    // SET_MAPPINGS_RESPONSE: "SET_MAPPINGS_RESPONSE",
    // LOADING_SET_MAPPINGS: "",
    // GET_BATCH_REQUESTS: "GET_BATCH_REQUESTS",
    // SET_BATCH_REQUESTS: "SET_BATCH_REQUESTS",
    SET_LOADING_BATCH_REQUESTS: "SET_LOADING_BATCH_REQUESTS",
    MAPPINGS: {
      SET_UPLOAD_FILE_HEADERS: "SET_UPLOAD_FILE_HEADERS",
      SET_MAPPINGS: "SET_MAPPINGS",
      SETTING_HEADER_MAPPINGS_FOR_BATCH: "SETTING_HEADER_MAPPINGS_FOR_BATCH",
      LOADING: "LOADING_SET_MAPPINGS",
    },
    BATCH_REQUESTS: {
      GET_BATCH_DETAILS: "GET_BATCH_DETAILS",
      GET_BATCH_REQUESTS: "GET_BATCH_REQUESTS",
      GET_REQUESTS_IN_A_BATCH: "GET_REQUESTS_IN_A_BATCH",
      GET_STATS_IN_BATCH: "GET_STATS_IN_BATCH",
      LOADING_REQUESTS_IN_BATCH: "LOADING_REQUESTS_IN_BATCH",
      LOADING: "LOADING_BATCH_REQUESTS",
      LOADING_STATS_IN_BATCH: "LOADING_STATS_IN_BATCH",
    },
  },
  SINGLE: {
    BANK_LIST: {
      LOADING: "LOADING_BANK_LIST",
      GET_BANK_LIST: "GET_BANK_LIST",
    },
    NETWORK_LIST: {
      LOADING: "LOADING_NETWORK_LIST",
      GET_NETWORK_LIST: "GET_NETWORK_LIST",
    },
  },
};

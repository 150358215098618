import * as Request from "utils/Request";
import { baseUrl } from "constants/ApiConstant";


const transfersBaseUrl = `${baseUrl}/transfers`;

export const paymentMapAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/pay-map`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const billingCollectionsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/billing/collections`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const onboardingPaymentOptionMapAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/onboarding/pay-option-map`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const transactionStatAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/transaction/stat`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const clearCustomersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/customers/clear`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const downloadCustomersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/customers/download`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const saveCustomersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/customer/save`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}


export const deleteCustomersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/customer/delete`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getCustomersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/customers`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getSettlementsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/settlements`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getSettlementsDetailsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/advice/settlement/get`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const markSettlementsProcessedAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/advice/settlement/process`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const deliverSettlementsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/advice/settlement/deliver`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const transactionRefundAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/order/transaction/refund`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const transactionReversalAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/order/transaction/reversal`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const transactionListAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/order/transaction/list`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const reportListAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/report/list`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const accountLoadAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/user/load`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const accountElevyLoadAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/user/elevyStatus`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const reportGenerateAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/report/generate`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const fulfillOrdersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/order/fulfill`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}



export const getCollectionAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/collection/list`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getOrdersAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/order/list`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const checkStatusAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/order/checkStatus`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getTransactionHistoryAPI = async (body) => {
  try {
    const userInfo = localStorage.getItem("userInfo");
    if(userInfo){
      const user = JSON.parse(userInfo);
      body.requestedBy = user?.preferred_username;
      body.accountId = user?.accountId;
    }

    const url = `${transfersBaseUrl}/billing/history`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const uploadBulkFileAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/file/upload`;
    const headers = Request.authHeader();
    delete headers["Content-Type"];
    const res = await Request.POST_FILE(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const setMappingsForBatchAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/file/headers/map`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getBatchDetailsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/file/details`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);

    return res;
  } catch (error) {
    return error.response;
  }
};

export const getBatchRequestsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/requests/search`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const searchBatchRequestsAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/requests/search`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const getRequestsInABatchAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/requests/${body.batchId}?page=${body.page}&size=${body.size}`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const searchRequestsInABatchAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/requests/${body.batchId}/search?page=${body.page}&size=${body.size}&term=${body.term}&status=${body.status}`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const getStatsInBatchAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/stats/${body.batchId}/${body.key}`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const processBatchAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/process-batch`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const requestBatchApprovalAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/file/approval/request`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const getInstitutionListAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/switching/options`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const customerNumberLookUp = async (body) => {
  try {
    const url = `${transfersBaseUrl}/switching/account`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const initiateSingleTransferRequest = async (body) => {
  try {
    const url = `${baseUrl}/request/init/creditAccount/bulk`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response || error;
  }
};

export const initiateSingleBillRequest = async (body) => {
  try {
    const url = `${baseUrl}/request/init/payBill/bulk`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response || error;
  }
};
export const updateBatchStatusAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/batch/status`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response || error;
  }
};

export const updatEntryStatusAPI = async (body) => {
  try {
    const url = `${transfersBaseUrl}/entry/status`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response || error;
  }
};

export const getBatchExecutionReport = async (body) => {
  try {
    const url = `${transfersBaseUrl}/file/report`;
    const headers = Request.authHeader();
    const res = await Request.POST(url, body, headers);
    return res;
  } catch (error) {
    return error.response || error;
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR'; // Ant Design locale for French
import moment from 'moment';
import 'moment/locale/fr';



moment.locale('fr');

ReactDOM.render( 
<ConfigProvider locale={frFR}>
    <App />
  </ConfigProvider>,
   document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}

import { Circle } from "redux/constants/Circles";

const initState = {
    data: [],
    loading: false,
  };

  const circle = (state = initState, action) => {
    switch (action.type) {
      case Circle.LOADING_CIRCLE:
        return {
          ...state,
          loading: action.payload,
        };
      case Circle.GET_CIRCLE:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default circle;
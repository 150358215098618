import { Transactions } from "redux/constants/Transactions";

const initState = {
    data: [],
    loading: false,
  };
  
  const transactions = (state = initState, action) => {
    switch (action.type) {
      case Transactions.LOADING_TRANSACTIONS_LIST:
        return {
          ...state,
          loading: action.payload,
        };
      case Transactions.GET_TRANSACTIONS_LIST:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default transactions;
export const Accounts = {
  SUMMARY_STATS: {
    GET_STATS: "GET_STATS",
    LOADING_STATS: "LOADING_STATS",
  },
  TRANSACTION_HISOTRY: {
    GET_TRANSACTION_HISTORY: "GET_TRANSACTION_HISTORY",
    LOADING_TRANSACTION_HISTORY: "LOADING_TRANSACTION_HISTORY",
  },
  ACCOUNTS_DATA: {
    LOADING_ACCOUNTS: "LOADING_ACCOUNTS",
    LOADING_ACCOUNT_BLANCE: "LOADING_ACCOUNT_BLANCE",
    LOADING_TOPUPS_TRANSFERS: "LOADING_TOPUPS_TRANSFERS",
    LOADING_TOPUPS_VS_TRANSFERS: "LOADING_TOPUPS_VS_TRANSFERS",
    GET_ACCOUNT_BALANCE: "GET_ACCOUNT_BALANCE",
    GET_ACCOUNTS: "GET_ACCOUNTS",
    SET_SELECTED_ACCOUNT: "SET_SELECTED_ACCOUNT",
    GET_TOPUPS_TRANSFERS: "GET_TOPUPS_TRANSFERS",
    GET_TOPUPS_VS_TRANSFERS: "GET_TOPUPS_VS_TRANSFERS",
  },
  LOADING_SUBACCOUNTS_LIST: "LOADING_SUBACCOUNTS_LIST",
  GET_SUBACCOUNTS_LIST: "GET_SUBACCOUNTS_LIST",
};

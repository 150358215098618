import React from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

let intl;
const IntlMessage = (props) => {
  intl = useIntl();

  return <FormattedMessage {...props} />;
};

export { intl };

export default injectIntl(IntlMessage, {
  withRef: false,
});

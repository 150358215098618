import { Topups } from "redux/constants/Topups";

const initState = {
  data: [],
  loading: false,
};

const topups = (state = initState, action) => {
  switch (action.type) {
    case Topups.LOADING_TOPUP_REQUESTS:
      return {
        ...state,
        loading: action.payload,
      };
    case Topups.GET_TOPUP_REQUESTS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default topups;

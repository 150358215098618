import { Collection } from "redux/constants/Collection";

const initState = {
    data: [],
    loading: false,
  };

  const collection = (state = initState, action) => {
    switch (action.type) {
      case Collection.LOADING_COLLECTION_LIST:
        return {
          ...state,
          loading: action.payload,
        };
      case Collection.GET_COLLECTION_LIST:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default collection;
import { GET_MENU } from "../constants/Menu";
// import { getMenu } from "api/menu";
// import { successCode } from "constants/ApiConstant";
// import { APP_PREFIX_PATH } from "configs/AppConfig";
import { menu } from "utils/menu";

// const updateStatus = (status, statusInfo) => {
//   return {
//     type: status,
//     payload: statusInfo,
//   };
// };

export const getGridMenu = (body) => {
  return {
    type: GET_MENU,
    payload: menu
  };
  // return async (dispatch) => {
  //   const result = await getMenu(body)
  //   if(result?.data?.success){
  //     let newMenu = result.data.result.map(item => {
  //       return(
  //         {
  //           key: item.id,
  //           path: `${APP_PREFIX_PATH}/${item.url}`,
  //           title: `sidenav.menu.${item.url}`,
  //           name: item.name,
  //           icon: item.image_small,
  //           breadcrump: false,
  //           submenu: []
  //         }
  //       )
  //     })
  //     dispatch({ type: GET_MENU, payload: newMenu });
  //   }
  //   else{
  //     dispatch({ type: GET_MENU, payload: [] });
  //   }
  // }
}
import { Modal ,Form, Input} from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import React from 'react'
import { useIntl } from 'react-intl'

const ChangePasswordModal = ({onCancel,visible,onFinish,form}) => {

const intl = useIntl()
  return (
    <div>
        <Modal onCancel={onCancel} visible={visible} okText={<IntlMessage id="topNav.changePassword.save"/>}
        onOk={()=>form.submit()}
        >
        <Form form={form}
        onFinish={onFinish} layout="vertical"  >
            <Form.Item label={<IntlMessage id="topNav.changePassword.currentPassword"/>} name="currentPassword" 
            rules={[
                {
                    required:true,
                    label:intl.formatMessage({
                        id:"topNav.changePassword.currentPassword"
                    })
                    
                },
                {
                    type:"string",
                    max:30,
                    label:intl.formatMessage({
                      id:"topNav.changePassword.save"
                    })
                  },
            ]}>
                <Input.Password/>
            </Form.Item>
            <Form.Item label={<IntlMessage id="topNav.changePassword.newPassword"/>} name="newPassword"
            rules={[
                {
                    required:true,
                    label:intl.formatMessage({
                        id:"topNav.changePassword.newPassword"
                      })
                },
                {
                type:"string",
                max:30,
                min:8,
                label:intl.formatMessage({
                  id:"topNav.changePassword.newPassword"
                })
                },
                {
                    pattern: new RegExp(/(?=.*[A-Z])/),
                    message : intl.formatMessage({
                        id:"topNav.changePassword.newPassword.letter"
                    })
                },
                { 
                pattern: new RegExp(/(?=.*[0-9])/),
                min:0,
                message:intl.formatMessage({
                    id:"topNav.changePassword.newPassword.number"
                })
               },
               { 
                pattern: new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
                min:0,
                message:intl.formatMessage({
                    id:"topNav.changePassword.newPassword.specialCharacters"
                })
               }

        ]}
            >
                <Input.Password/>
            </Form.Item>

        </Form>
        </Modal>
        </div>
  )
}

export default ChangePasswordModal
import { Billers } from "redux/constants/Billers";


const initState = {
  data: [],
  loading: false,
};

const billers = (state = initState, action) => {
  switch (action.type) {
    case Billers.LOADING_BILLERS_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Billers.GET_BILLERS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default billers;
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import { getGridMenu } from "redux/actions/Menu";
import { useDispatch, useSelector } from "react-redux";
import "./MenuContent.scss";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const getUserRoles = () => {
  const rolesString = localStorage.getItem("roles");
  return rolesString;
};

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const getDefaultSelectedKey = (menu, location) => {
  const keys = menu.reduce((s = [], m) => {
    if (location.pathname?.startsWith(m.path)) {
      s.push(m.key.toString());
    }
    return s;
  }, []);
  return keys;
};

const SideNavContent = (props) => {
  const userRoles = getUserRoles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { menu } = useSelector((state) => state.menu);
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  useEffect(() => {
    dispatch(getGridMenu({}));
  }, [dispatch]);

  const selectedMenuKeys = getDefaultSelectedKey(menu, location);

  const filteredMenu = menu?.filter((menuItem) => {
    return (
      !menuItem.roles || menuItem.roles.some((role) => userRoles.includes(role))
    );
  });

  const renderSubMenu = (subMenu) => {
    const filteredMenu = subMenu?.filter((menuItem) => {
      return (
        !menuItem.roles ||
        menuItem.roles.some((role) => userRoles.includes(role))
      );
    });
    return filteredMenu.map((item) => (
      <Menu.Item key={item.key} icon={item.icon}>
        <span>{setLocale(localization, item.title)}</span>
        <Link
          onClick={() => {
            closeMobileNav();
          }}
          to={item.path}
        />
      </Menu.Item>
    ));
  };

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      // defaultSelectedKeys={[routeInfo?.key]}
      selectedKeys={selectedMenuKeys}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={`sidenav ${hideGroupTitle ? "hide-group-title" : ""}`}
    >
      {filteredMenu.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuSecond.path}
                      />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <>
            {menu.children ? (
              <SubMenu
                title={setLocale(localization, menu.title)}
                icon={menu.icon}
                onTitleClick={() => (
                  <Link
                    onClick={() => {
                      closeMobileNav();
                    }}
                    to={menu.path}
                  />
                )}
              >
                {renderSubMenu(menu.children)}
              </SubMenu>
            ) : (
              <Menu.Item key={menu.key} className="dashboard">
                {menu.icon ? (
                  typeof menu.icon === "string" ? (
                    <img
                      style={{ marginRight: "10px", display: "inline-block" }}
                      src={`/img/icons/${menu.icon}`}
                      alt="Menu icon"
                    />
                  ) : (
                    menu.icon
                  )
                ) : (
                  <span style={{ width: "27px", display: "inline-block" }} />
                )}
                <span>{setLocale(localization, menu?.title)}</span>
                {menu.path ? (
                  menu.path.startsWith("http") ? (
                    <Link to={{ pathname: menu.path }} target="_blank" />
                  ) : (
                    <Link onClick={() => closeMobileNav()} to={menu.path} />
                  )
                ) : null}
              </Menu.Item>
            )}
          </>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);

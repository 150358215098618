import { Approvals } from "redux/constants/Approvals";

const initState = {
    data: [],
    loading: false,
};

const approvals =(state = initState, action) => {
    switch (action.type) {
        case Approvals.LOADING_APPROVALS_LIST:
            return {
                ...state,
                loading: action.payload,
            };

        case Approvals.GET_APPROVALS_LIST:
            return {
                ...state,
                data: action.payload,
            };

        default:
            return state;
    }
};

export default approvals;
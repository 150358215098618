import { getRecentLoginsAPI } from "api/auth";
import { getTransactionHistoryAPI } from "api/transfers";
import { recentLogins } from "utils/samples";
import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  RECENT_ACTIVITY,
  RECENT_ACTIVITY_LOADING,
  RECENT_LOGINS_LOADING,
  RECENT_LOGINS,
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

const setLoadingRecentActivity = (loading) => {
  return {
    type: RECENT_ACTIVITY_LOADING,
    payload: loading,
  };
};
const setLoadingRecentLogins = (loading) => {
  return {
    type: RECENT_LOGINS_LOADING,
    payload: loading,
  };
};

export const getRecentActivity = (body) => {
  return async (dispatch) => {
    dispatch(setLoadingRecentActivity(true));
    const result = await getTransactionHistoryAPI(body);
    if (result?.data?.status) {
      let activity = result.data.data;
      dispatch({
        type: RECENT_ACTIVITY,
        payload: activity,
      });
    }
    dispatch(setLoadingRecentActivity(false));
  };
};
export const getRecentLogins = (token) => {
  return async (dispatch) => {
    dispatch(setLoadingRecentLogins(true));
    const result = await getRecentLoginsAPI(token);
    if (result?.data?.status) {
      let logins = result.data.result;
      dispatch({
        type: RECENT_LOGINS,
        payload: logins,
      });
    }
    setTimeout(() => {
      dispatch(setLoadingRecentLogins(false));
    }, 2000);
  };
};

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  // CheckCircleFilled,
  MinusOutlined,
  MoneyCollectOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const menus = [
  {
    key: 0,
    path: `${APP_PREFIX_PATH}/accounts`,
    title: `sidenav.menu.accounts`,
    name: "Accounts",
    icon: <UserOutlined />,
    // icon: 'accounts.svg',
    breadcrump: false,
    submenu: [],
  },
  // {
  //   key: 1,
  //   path: `${APP_PREFIX_PATH}/transfers`,
  //   title: `sidenav.menu.transfers`,
  //   name: "Transfers",
  //   icon: "transfers.svg",
  //   breadcrump: false,
  //   submenu: [],
  // },
  // {
  //   key: 2,
  //   path: `${APP_PREFIX_PATH}/merchants-and-services`,
  //   title: `sidenav.menu.merchs_and_services`,
  //   name: "MerchantsAndServices",
  //   icon: <ShoppingFilled />,
  //   // icon: 'merchants_n_services.svg',
  //   breadcrump: false,
  //   submenu: [],
  // },
  // {
  //   key: 3,
  //   path: `${APP_PREFIX_PATH}/beneficiaries`,
  //   title: `sidenav.menu.beneficiaries`,
  //   name: "Beneficiaries",
  //   icon: "beneficiaries.svg",
  //   breadcrump: false,
  //   submenu: [],
  // },
  {
    key: 4,
    path: `${APP_PREFIX_PATH}/approvals`,
    title: `sidenav.menu.approvals`,
    name: "Approvals",
    // icon: <CheckCircleFilled />,
    icon: "approvals.svg",
    breadcrump: false,
    submenu: [],
  },
];

export const stats_orig = {
  overview: [
    {
      icon: <MoneyCollectOutlined />,
      iconbg: "#4D7DB8",
      unit: "",
      description: "Account Balance",
      value: "14324.40",
    },
    {
      icon: <ArrowUpOutlined />,
      iconbg: "#2C57D8",
      unit: "",
      description: "Outgoing Transactions",
      value: "324",
    },
    {
      icon: <ArrowDownOutlined />,
      iconbg: "#20326D",
      unit: "GHS",
      description: "Incoming Transactions",
      value: "14324.449",
    },
    {
      icon: <MinusOutlined />,
      iconbg: "#0BB07B",
      unit: "GHS",
      description: "Debit Transactions",
      value: "14324.40",
    },
    {
      icon: <PlusOutlined />,
      unit: "GHS",
      description: "Credit Transactions",
      value: "1324.40",
    },
  ],
};

export const setMappingsSuccessResponse = {
  status: true,
  result: "4fd93cda-f4b8-433d-bb68-e8680034f780",
  message: "success",
  count: 0,
};
export const setMappingsFailureResponse = {
  status: false,
  result: 117,
  message: "Please provide a valid file id.",
  count: 0,
};

export const bulkTransferBatch = {
  status: true,
  result: [
    {
      id: "628766bb03c47c7e84aa04b3",
      batchId: "8cde5d8e-94ce-4791-b86c-fe80a89215b4",
      url: "/switching/credit",
      dateCreated: null,
      status: "PROCESSED",
      requestData: {
        senderName: "Kofi Asamoah",
        senderNumber: "233244733999",
        amount: 1,
        institutionCode: "mtn-money",
        accountName: "Kofi Asamoah",
        requestId: "b068f1e7-75e7-4c52-a85f-6f28521c279c",
        narration: "Testing File Processor",
        currency: "GHS",
        accountNumber: "233244733999",
        transactionDate: "2022-05-20T10:00:08",
        transactionId: "20211230145001",
      },
      result:
        '{"requestId":"b068f1e7-75e7-4c52-a85f-6f28521c279c","serviceCode":"23324","serviceName":null,"methodName":"creditAccount","apiType":null,"details":{"status":"CONFIRMED","receiverTransId":"3247391369","receiverAccountName":"Kofi Asamoah","receiverAccountNumber":"233244733999","senderName":"Kofi Asamoah","senderNumber":"233244733999","senderTransId":"20211230145001"},"success":true,"statusCode":"SUCCESS","statusMessage":"Successfully processed transaction.","result":null}',
      dateProcessed: 1653041350887,
    },
    {
      id: "628766bc03c47c7e84aa04b4",
      batchId: "8cde5d8e-94ce-4791-b86c-fe80a89215b4",
      url: "/switching/credit",
      dateCreated: null,
      status: "PROCESSED",
      requestData: {
        senderName: "Kofi Asamoah",
        senderNumber: "233244733999",
        amount: 1,
        institutionCode: "vodafone-cash",
        accountName: "Kofi Asamoah",
        requestId: "6d13a306-6191-4276-b3ba-d5491e408a84",
        narration: "Testing File Processor",
        currency: "GHS",
        accountNumber: "233208418857",
        transactionDate: "2022-05-20T10:00:27",
        transactionId: "20211230145002",
      },
      result:
        '{"requestId":"6d13a306-6191-4276-b3ba-d5491e408a84","serviceCode":"23320","serviceName":null,"methodName":"creditAccount","apiType":null,"details":{"status":"PENDING","receiverTransId":"AG_20190305_00006b84cda238931e60","receiverAccountName":"Kofi Asamoah","receiverAccountNumber":"233208418857","senderName":"Kofi Asamoah","senderNumber":"233244733999","senderTransId":"20211230145002"},"success":true,"statusCode":"SUCCESS","statusMessage":"Accept the service request successfully.","result":null}',
      dateProcessed: 1653041351513,
    },
    {
      id: "628766bc03c47c7e84aa04b4",
      batchId: "8cde5d8e-94ce-4791-b86c-fe80a89215b4",
      url: "/switching/credit",
      dateCreated: null,
      status: "FAILED",
      requestData: {
        senderName: "John Doe",
        senderNumber: "233244000001",
        amount: 124750,
        institutionCode: "vodafone-cash",
        accountName: "John Doe",
        requestId: "6d13a306-6191-4276-b3ba-d549asdf48a84",
        narration: "Lorem typed",
        currency: "AED",
        accountNumber: "233208418823",
        transactionDate: "2022-05-20T10:00:27",
        transactionId: "20211230145003",
      },
      result:
        '{"requestId":"6d13a306-6191-4276-b3ba-d5491e408a84","serviceCode":"23320","serviceName":null,"methodName":"creditAccount","apiType":null,"details":{"status":"PENDING","receiverTransId":"AG_20190305_00006b84cda238931e60","receiverAccountName":"Kofi Asamoah","receiverAccountNumber":"233208418857","senderName":"Kofi Asamoah","senderNumber":"233244733999","senderTransId":"20211230145002"},"success":true,"statusCode":"SUCCESS","statusMessage":"Accept the service request successfully.","result":null}',
      dateProcessed: 1653041351513,
    },
  ],
  message: "Requests retrieved successfully",
  count: 0,
};

export const _transactionHistory = [
  {
    id: 1,
    name: "Rikki Powlett",
    amount: "83917",
    account_number: 730915834427,
    bank_name: "Gislason-Tromp",
    branch_name: "nicotine",
    transaction_date: "1639957194000",
  },
  {
    id: 2,
    name: "Kincaid Omrod",
    amount: "2814",
    account_number: 784294849355,
    bank_name: "Weissnat LLC",
    branch_name: "Bodycology",
    transaction_date: "1642983656000",
  },
  {
    id: 3,
    name: "Fons Ranvoise",
    amount: "351",
    account_number: 657004142146,
    bank_name: "Huels, Donnelly and Conroy",
    branch_name: "Avocado",
    transaction_date: "1632480734000",
  },
  {
    id: 4,
    name: "Harrison Rizzone",
    amount: "7684",
    account_number: 232583072610,
    bank_name: "Kris, Flatley and Ruecker",
    branch_name: "good sense ibuprofen",
    transaction_date: "1626754352000",
  },
  {
    id: 5,
    name: "Beverly Antuoni",
    amount: "6",
    account_number: 731579218004,
    bank_name: "Watsica, Kshlerin and Wilkinson",
    branch_name: "Old Spice Red Zone Sweat Defense",
    transaction_date: "1635809458000",
  },
  {
    id: 6,
    name: "Martina Larkcum",
    amount: "6",
    account_number: 801229603806,
    bank_name: "Tromp-Ruecker",
    branch_name: "ESIKA",
    transaction_date: "1626921542000",
  },
  {
    id: 7,
    name: "Scarlet Cheesman",
    amount: "50865",
    account_number: 148229891449,
    bank_name: "Moen LLC",
    branch_name:
      "CLARINS Skin Illusion SPF 10 Natural Radiance Foundation Tint 112.5",
    transaction_date: "1625719206000",
  },
  {
    id: 8,
    name: "Clayborn Fossick",
    amount: "9",
    account_number: 944091163954,
    bank_name: "Rogahn-Kozey",
    branch_name:
      "No7 Beautiful Skin Day Dry Very Dry Sunscreen Broad Spectrum SPF 15",
    transaction_date: "1631781019000",
  },
  {
    id: 9,
    name: "Sloan Goodwins",
    amount: "259",
    account_number: 268537659697,
    bank_name: "Cruickshank-Sanford",
    branch_name: "Pollens - Grasses, Bahia Grass Paspalum notatum",
    transaction_date: "1629508302000",
  },
  {
    id: 10,
    name: "Jennica Minchinton",
    amount: "6393",
    account_number: 959549236496,
    bank_name: "Koss, Hartmann and Marks",
    branch_name: "Saline Laxative",
    transaction_date: "1643746778000",
  },
  {
    id: 11,
    name: "Florencia Bresnahan",
    amount: "1",
    account_number: 844286098357,
    bank_name: "Kuhn and Sons",
    branch_name: "Bee - Bug Bite",
    transaction_date: "1626156932000",
  },
  {
    id: 12,
    name: "Egon Dupree",
    amount: "51340",
    account_number: 588524607604,
    bank_name: "Fritsch-Metz",
    branch_name: "Lamotrigine",
    transaction_date: "1647134983000",
  },
  {
    id: 13,
    name: "Lucius Nugent",
    amount: "4299",
    account_number: 167684487505,
    bank_name: "Rice, Becker and Schmeler",
    branch_name: "Lithium Carbonate",
    transaction_date: "1642041128000",
  },
  {
    id: 14,
    name: "Jeff Maytom",
    amount: "548",
    account_number: 489605824365,
    bank_name: "MacGyver-Larson",
    branch_name: "ETODOLAC",
    transaction_date: "1651993086000",
  },
  {
    id: 15,
    name: "Dalenna Kench",
    amount: "86224",
    account_number: 802342007200,
    bank_name: "Zulauf, O'Hara and Keeling",
    branch_name: "Bupivacaine Hydrochloride and Epinephrine",
    transaction_date: "1625353247000",
  },
  {
    id: 16,
    name: "Rodrigo Southeran",
    amount: "1",
    account_number: 545594083065,
    bank_name: "McKenzie and Sons",
    branch_name: "Childrens SUDAFED PE Cold and Cough",
    transaction_date: "1647340049000",
  },
  {
    id: 17,
    name: "Constantia Brewitt",
    amount: "1",
    account_number: 716694357286,
    bank_name: "Blanda, Rath and Abbott",
    branch_name: "Arnica e pl. tota 30",
    transaction_date: "1621341603000",
  },
  {
    id: 18,
    name: "Gwendolyn Farre",
    amount: "7",
    account_number: 959853625085,
    bank_name: "Morar, Boehm and Baumbach",
    branch_name: "Niravam",
    transaction_date: "1636396819000",
  },
  {
    id: 19,
    name: "Ingra Heading",
    amount: "42",
    account_number: 121220680166,
    bank_name: "Padberg-Keeling",
    branch_name: "Diclofenac Sodium and Misoprostol",
    transaction_date: "1631014372000",
  },
  {
    id: 20,
    name: "Franky Kirrage",
    amount: "34154",
    account_number: 915600003909,
    bank_name: "D'Amore, Koch and Greenholt",
    branch_name: "Propoxyphene Hydrochloride and Acetaminophen",
    transaction_date: "1638942639000",
  },
  {
    id: 21,
    name: "Rhett Cathesyed",
    amount: "896",
    account_number: 907853724625,
    bank_name: "Prohaska and Sons",
    branch_name: "Atenolol",
    transaction_date: "1628896599000",
  },
  {
    id: 22,
    name: "Ernst Mora",
    amount: "7316",
    account_number: 938802012202,
    bank_name: "Jakubowski and Sons",
    branch_name: "Kit for the Prepartion of Technetium Tc99m Sulfur Colloid",
    transaction_date: "1640393493000",
  },
  {
    id: 23,
    name: "Wini Amiranda",
    amount: "673",
    account_number: 165160567327,
    bank_name: "Casper, Heller and Rohan",
    branch_name: "Hydrocodone Bitartrate and Acetaminophen",
    transaction_date: "1651810725000",
  },
  {
    id: 24,
    name: "Welch Brind",
    amount: "9",
    account_number: 248854766496,
    bank_name: "Blanda, Howell and Corwin",
    branch_name: "Sterile Isotonic Buffered Genuine Eyewash",
    transaction_date: "1645321925000",
  },
  {
    id: 25,
    name: "Angelia Dobrovsky",
    amount: "8",
    account_number: 312171419657,
    bank_name: "Bogan-Medhurst",
    branch_name: "Albuminuriaforce",
    transaction_date: "1634373785000",
  },
  {
    id: 26,
    name: "Vicky Shipman",
    amount: "5646",
    account_number: 741457870176,
    bank_name: "Harber, Stiedemann and Brekke",
    branch_name: "Atenolol",
    transaction_date: "1623706808000",
  },
  {
    id: 27,
    name: "Felicity Rhydderch",
    amount: "9",
    account_number: 875662838313,
    bank_name: "Moen, Welch and Prosacco",
    branch_name: "Eye Wash",
    transaction_date: "1628605600000",
  },
  {
    id: 28,
    name: "Fleming Odegaard",
    amount: "5036",
    account_number: 674790719950,
    bank_name: "Herzog, Deckow and Ullrich",
    branch_name: "Jasmine Water Cover Cushion",
    transaction_date: "1638496459000",
  },
  {
    id: 29,
    name: "Jaquenetta Torn",
    amount: "63",
    account_number: 632857410776,
    bank_name: "Orn, Goyette and Schiller",
    branch_name: "Edex",
    transaction_date: "1642987885000",
  },
  {
    id: 30,
    name: "Valeda Petters",
    amount: "98783",
    account_number: 446305554646,
    bank_name: "Koelpin, Lowe and Beer",
    branch_name: "Wal-Dryl",
    transaction_date: "1638908741000",
  },
  {
    id: 31,
    name: "Archibald McCorry",
    amount: "4",
    account_number: 166624501025,
    bank_name: "Baumbach-Luettgen",
    branch_name: "LISINOPRIL",
    transaction_date: "1623753482000",
  },
  {
    id: 32,
    name: "Elwood Scotts",
    amount: "587",
    account_number: 297292757621,
    bank_name: "Wilderman LLC",
    branch_name: "Antacid Antigas",
    transaction_date: "1633834203000",
  },
  {
    id: 33,
    name: "Desirae Pocklington",
    amount: "08",
    account_number: 680948274802,
    bank_name: "Farrell-Bogisich",
    branch_name: "diltiazem hydrochloride",
    transaction_date: "1649194540000",
  },
  {
    id: 34,
    name: "Bobbye Lofting",
    amount: "901",
    account_number: 644256059619,
    bank_name: "Abernathy, Okuneva and Jacobs",
    branch_name: "Speed Stick",
    transaction_date: "1628810460000",
  },
  {
    id: 35,
    name: "Garik Blindermann",
    amount: "3",
    account_number: 935389161985,
    bank_name: "Crooks-Ratke",
    branch_name: "KAO- TIN",
    transaction_date: "1632739928000",
  },
  {
    id: 36,
    name: "Kassia Darlison",
    amount: "2741",
    account_number: 102806243066,
    bank_name: "Braun-Greenfelder",
    branch_name: "Phenobarbital",
    transaction_date: "1628747976000",
  },
  {
    id: 37,
    name: "Murial Alf",
    amount: "8",
    account_number: 303800725784,
    bank_name: "Treutel-Wolf",
    branch_name: "Abdominal Cramps",
    transaction_date: "1648051462000",
  },
  {
    id: 38,
    name: "Elana Motte",
    amount: "289",
    account_number: 310288404237,
    bank_name: "Shields-Fritsch",
    branch_name: "COARTEM",
    transaction_date: "1645133016000",
  },
  {
    id: 39,
    name: "Murray Gormally",
    amount: "29",
    account_number: 788659755876,
    bank_name: "Rippin-Balistreri",
    branch_name: "POMADA DRAGON PAIN RELIEVING",
    transaction_date: "1622990190000",
  },
  {
    id: 40,
    name: "Udale Folder",
    amount: "54111",
    account_number: 988738407940,
    bank_name: "Rohan, Rolfson and Considine",
    branch_name: "Levothyroxine Sodium",
    transaction_date: "1635938397000",
  },
  {
    id: 41,
    name: "Ward Kobiera",
    amount: "47",
    account_number: 218434186602,
    bank_name: "Fahey, Kub and Jacobson",
    branch_name: "Levothyroxine Sodium",
    transaction_date: "1647403744000",
  },
  {
    id: 42,
    name: "Karlis Troup",
    amount: "7",
    account_number: 467063503987,
    bank_name: "Roob-Strosin",
    branch_name: "NICOTINE",
    transaction_date: "1630194552000",
  },
  {
    id: 43,
    name: "Gertrudis Rathbone",
    amount: "308",
    account_number: 777310020679,
    bank_name: "Boyle, Waelchi and Stark",
    branch_name: "Lorazepam",
    transaction_date: "1621917330000",
  },
  {
    id: 44,
    name: "Shelby Copo",
    amount: "570",
    account_number: 184834600181,
    bank_name: "Leuschke, Howe and Pollich",
    branch_name: "Methscopolamine Bromide",
    transaction_date: "1641736816000",
  },
  {
    id: 45,
    name: "Malvina Stroyan",
    amount: "481",
    account_number: 302257020032,
    bank_name: "Hane, Leuschke and Spencer",
    branch_name: "isosorbide mononitrate",
    transaction_date: "1632538241000",
  },
  {
    id: 46,
    name: "Theodor Grinvalds",
    amount: "5604",
    account_number: 164375189204,
    bank_name: "Russel, Dietrich and Ruecker",
    branch_name: "Valacyclovir Hydrochloride",
    transaction_date: "1633815064000",
  },
  {
    id: 47,
    name: "Cristabel Ingledew",
    amount: "2",
    account_number: 540440198560,
    bank_name: "Hyatt-Kertzmann",
    branch_name: "Klor-Con",
    transaction_date: "1628315069000",
  },
  {
    id: 48,
    name: "Willow Gourdon",
    amount: "1",
    account_number: 868016489524,
    bank_name: "O'Conner, Sauer and Wilkinson",
    branch_name: "Antimicrobial",
    transaction_date: "1631002157000",
  },
  {
    id: 49,
    name: "Grayce Hein",
    amount: "35",
    account_number: 751215738685,
    bank_name: "Mayert Group",
    branch_name: "Potassium Chloride",
    transaction_date: "1621650384000",
  },
  {
    id: 50,
    name: "Rickie Dallyn",
    amount: "42078",
    account_number: 626960037649,
    bank_name: "Gulgowski Inc",
    branch_name: "Iodine Bush",
    transaction_date: "1643925356000",
  },
  {
    id: 51,
    name: "Carter Lomen",
    amount: "62",
    account_number: 527844045361,
    bank_name: "Jaskolski, Sanford and Pfannerstill",
    branch_name: "ADENOSINE",
    transaction_date: "1634030978000",
  },
  {
    id: 52,
    name: "Woodie Alvarado",
    amount: "051",
    account_number: 732958282459,
    bank_name: "D'Amore and Sons",
    branch_name: "Dr. Dream Advanced Eye Treatment",
    transaction_date: "1650506260000",
  },
  {
    id: 53,
    name: "Garner Dwire",
    amount: "6440",
    account_number: 323958570557,
    bank_name: "Hansen-Waelchi",
    branch_name: "MULTI ACTION POWER",
    transaction_date: "1630944472000",
  },
  {
    id: 54,
    name: "Mathias Episcopi",
    amount: "518",
    account_number: 811704951170,
    bank_name: "Leffler Group",
    branch_name: "Renova",
    transaction_date: "1632697246000",
  },
  {
    id: 55,
    name: "Dorelle Cashell",
    amount: "79",
    account_number: 128862807706,
    bank_name: "Bosco-Paucek",
    branch_name: "Stay Awake",
    transaction_date: "1642520572000",
  },
  {
    id: 56,
    name: "Ingaberg Edgcombe",
    amount: "05",
    account_number: 202782618955,
    bank_name: "Runte-Hackett",
    branch_name: "Meijer Oil Free SPF 15",
    transaction_date: "1638658055000",
  },
  {
    id: 57,
    name: "Bernadette Handasyde",
    amount: "99",
    account_number: 350400931207,
    bank_name: "Balistreri, Wolf and Pacocha",
    branch_name: "JAKAFI",
    transaction_date: "1639439919000",
  },
  {
    id: 58,
    name: "Myrwyn Hanniger",
    amount: "026",
    account_number: 639853352057,
    bank_name: "Beer-Stoltenberg",
    branch_name: "ESSENTIAL SOLUTION PEARL MASK SHEET",
    transaction_date: "1628271422000",
  },
  {
    id: 59,
    name: "Laughton Carrane",
    amount: "5697",
    account_number: 290693223522,
    bank_name: "Carroll Inc",
    branch_name: "Hydrocodone Bitartrate And Acetaminophen",
    transaction_date: "1651002522000",
  },
  {
    id: 60,
    name: "Marilin Ebbitt",
    amount: "3313",
    account_number: 881298569227,
    bank_name: "Stiedemann-Cassin",
    branch_name: "Metoprolol Tartrate",
    transaction_date: "1644003043000",
  },
  {
    id: 61,
    name: "Crysta Dmych",
    amount: "28",
    account_number: 719879050084,
    bank_name: "Halvorson Group",
    branch_name: "Rizatriptan Benzoate",
    transaction_date: "1621469447000",
  },
  {
    id: 62,
    name: "Andree Keetch",
    amount: "926",
    account_number: 171147970650,
    bank_name: "Lockman, Kuhn and Roberts",
    branch_name: "Esomeprazole Sodium",
    transaction_date: "1625651279000",
  },
  {
    id: 63,
    name: "Ginevra Heiner",
    amount: "2411",
    account_number: 388988615883,
    bank_name: "Bednar, Conroy and Abernathy",
    branch_name: "Topiramate",
    transaction_date: "1629403842000",
  },
  {
    id: 64,
    name: "Jaime Brack",
    amount: "6378",
    account_number: 160327990095,
    bank_name: "Nitzsche, MacGyver and Grady",
    branch_name: "Berry Scented Hand Sanitizer",
    transaction_date: "1647762801000",
  },
  {
    id: 65,
    name: "Rudd Thurner",
    amount: "95",
    account_number: 596979187761,
    bank_name: "Roberts-Nader",
    branch_name: "Pioglitazone",
    transaction_date: "1647911177000",
  },
  {
    id: 66,
    name: "Morgan Cocksedge",
    amount: "6266",
    account_number: 665089398512,
    bank_name: "Dickinson, Marvin and Medhurst",
    branch_name: "Antacid",
    transaction_date: "1638246215000",
  },
  {
    id: 67,
    name: "Kira Wensley",
    amount: "850",
    account_number: 772176228292,
    bank_name: "Cummings-Hartmann",
    branch_name: "Aspergillus fumigatus",
    transaction_date: "1652237521000",
  },
  {
    id: 68,
    name: "Anissa Wixon",
    amount: "78613",
    account_number: 831342474402,
    bank_name: "Kirlin Inc",
    branch_name: "ACETAMINOPHEN AND CODEINE",
    transaction_date: "1631954231000",
  },
  {
    id: 69,
    name: "Sumner Backen",
    amount: "7",
    account_number: 202488859854,
    bank_name: "Pfannerstill, Cronin and Sauer",
    branch_name: "Foundation Primer Plus SPF 15",
    transaction_date: "1643197955000",
  },
  {
    id: 70,
    name: "Roseanna Minucci",
    amount: "3634",
    account_number: 901926368675,
    bank_name: "Lakin and Sons",
    branch_name: "IOPE SS",
    transaction_date: "1639959857000",
  },
  {
    id: 71,
    name: "Cherin Mobberley",
    amount: "520",
    account_number: 350639544534,
    bank_name: "Hickle, McKenzie and Hermann",
    branch_name: "Antibacterial Moist Wipes",
    transaction_date: "1626453845000",
  },
  {
    id: 72,
    name: "Brendis Nesbitt",
    amount: "26989",
    account_number: 116112725018,
    bank_name: "Ruecker Inc",
    branch_name: "Chlorpromazine Hydrochloride",
    transaction_date: "1627962579000",
  },
  {
    id: 73,
    name: "Minna Crier",
    amount: "6",
    account_number: 739061917111,
    bank_name: "Russel LLC",
    branch_name: "EcoCare 350",
    transaction_date: "1631360425000",
  },
  {
    id: 74,
    name: "Skye Hallbord",
    amount: "87244",
    account_number: 447815086609,
    bank_name: "Rowe, Walsh and Tillman",
    branch_name: "Oxycodone Hydrochloride",
    transaction_date: "1644127819000",
  },
  {
    id: 75,
    name: "Latrina Pimblott",
    amount: "82",
    account_number: 336212670166,
    bank_name: "Okuneva Inc",
    branch_name: "ARSENICUM ALBUM",
    transaction_date: "1636454950000",
  },
  {
    id: 76,
    name: "Angil Duester",
    amount: "0",
    account_number: 632927104324,
    bank_name: "Prosacco LLC",
    branch_name: "Fluoxetine Hydrochloride",
    transaction_date: "1639098758000",
  },
  {
    id: 77,
    name: "Chariot Landrieu",
    amount: "415",
    account_number: 777226515706,
    bank_name: "Berge and Sons",
    branch_name: "Cyzone",
    transaction_date: "1642391932000",
  },
  {
    id: 78,
    name: "Laureen Carlyle",
    amount: "08",
    account_number: 127049478886,
    bank_name: "Blick and Sons",
    branch_name: "Hands First",
    transaction_date: "1632942398000",
  },
  {
    id: 79,
    name: "Nikolaos Scimone",
    amount: "27993",
    account_number: 500272990877,
    bank_name: "Veum LLC",
    branch_name: "Carbamazepine",
    transaction_date: "1630870579000",
  },
  {
    id: 80,
    name: "Marika Wofenden",
    amount: "742",
    account_number: 946953584665,
    bank_name: "O'Conner-Torphy",
    branch_name: "Temovate",
    transaction_date: "1635850712000",
  },
  {
    id: 81,
    name: "Christel Van Zon",
    amount: "363",
    account_number: 861734810212,
    bank_name: "Leuschke Inc",
    branch_name: "Allergy Relief",
    transaction_date: "1644278588000",
  },
  {
    id: 82,
    name: "Domingo Flescher",
    amount: "41812",
    account_number: 309316930536,
    bank_name: "Bergnaum-Smitham",
    branch_name: "citalopram hydrobromide",
    transaction_date: "1651169837000",
  },
  {
    id: 83,
    name: "Jocelyne Camock",
    amount: "13",
    account_number: 823794599897,
    bank_name: "Mayert-Bogisich",
    branch_name: "Adderall",
    transaction_date: "1633446662000",
  },
  {
    id: 84,
    name: "Florian Laible",
    amount: "17",
    account_number: 332509471057,
    bank_name: "Kunze-Jenkins",
    branch_name: "Rizatriptan Benzoate",
    transaction_date: "1643493996000",
  },
  {
    id: 85,
    name: "Anthea Harlock",
    amount: "166",
    account_number: 144072632500,
    bank_name: "Hilpert, Mraz and VonRueden",
    branch_name: "BETULA LENTA POLLEN",
    transaction_date: "1638962396000",
  },
  {
    id: 86,
    name: "Lusa Fowells",
    amount: "0540",
    account_number: 432334132313,
    bank_name: "Davis-Gorczany",
    branch_name: "Ribavirin",
    transaction_date: "1626845139000",
  },
  {
    id: 87,
    name: "Eugene Vaggs",
    amount: "38156",
    account_number: 829900556440,
    bank_name: "Schiller-Gaylord",
    branch_name: "Antimicrobial Hand Sanitizer",
    transaction_date: "1632684460000",
  },
  {
    id: 88,
    name: "Rora Hablot",
    amount: "9335",
    account_number: 654038134683,
    bank_name: "Kub LLC",
    branch_name: "IONTOPHORESIS",
    transaction_date: "1630342447000",
  },
  {
    id: 89,
    name: "Bearnard Tointon",
    amount: "4273",
    account_number: 328177896258,
    bank_name: "Schoen-Ondricka",
    branch_name: "TIAZAC",
    transaction_date: "1629511465000",
  },
  {
    id: 90,
    name: "Adel Peckett",
    amount: "960",
    account_number: 282784274640,
    bank_name: "VonRueden, Cummings and Stark",
    branch_name: "Ondansetron",
    transaction_date: "1623801825000",
  },
  {
    id: 91,
    name: "Jessi Le Marchant",
    amount: "35",
    account_number: 210779919592,
    bank_name: "Kassulke-Wintheiser",
    branch_name: "Personal Care",
    transaction_date: "1628975759000",
  },
  {
    id: 92,
    name: "Darbie Toquet",
    amount: "24",
    account_number: 877794669800,
    bank_name: "Graham-Cummerata",
    branch_name: "Dexmedetomidine Hydrochloride",
    transaction_date: "1640605492000",
  },
  {
    id: 93,
    name: "Edgard Andreichik",
    amount: "93",
    account_number: 399038868805,
    bank_name: "Abbott Inc",
    branch_name: "Darkleaves Mugwort",
    transaction_date: "1649006623000",
  },
  {
    id: 94,
    name: "Loraine Hill",
    amount: "2",
    account_number: 376125585147,
    bank_name: "Schamberger, Gleichner and MacGyver",
    branch_name: "Aspirin",
    transaction_date: "1643205378000",
  },
  {
    id: 95,
    name: "Aldous Esley",
    amount: "13491",
    account_number: 167842366267,
    bank_name: "Osinski-Waelchi",
    branch_name: "Hydralazine Hydrochloride",
    transaction_date: "1644059788000",
  },
  {
    id: 96,
    name: "Lindsey Shaxby",
    amount: "4",
    account_number: 138775651549,
    bank_name: "Ebert-Kohler",
    branch_name: "Lotensin HCT",
    transaction_date: "1634688730000",
  },
  {
    id: 97,
    name: "Meade Dionisio",
    amount: "3",
    account_number: 404130018390,
    bank_name: "Stroman Group",
    branch_name: "Desmopressin acetate",
    transaction_date: "1632670711000",
  },
  {
    id: 98,
    name: "Neel Kiledal",
    amount: "6",
    account_number: 856082401803,
    bank_name: "Rath-Murazik",
    branch_name: "family wellness hemorrhoidal",
    transaction_date: "1650463152000",
  },
  {
    id: 99,
    name: "Bran Fryer",
    amount: "643",
    account_number: 567576077140,
    bank_name: "Erdman and Sons",
    branch_name: "Asepxia",
    transaction_date: "1630820775000",
  },
  {
    id: 100,
    name: "Brigitta Gerald",
    amount: "37",
    account_number: 525281328302,
    bank_name: "Wolff LLC",
    branch_name: "Naproxen Sodium",
    transaction_date: "1621295911000",
  },
];
export const transactionHistory = [
  {
    amount: "20.0000",
    currency: "GHS",
    comment: "test",
    success: true,
    transactionId: "5775815331",
    receiptNo: "AG_20190305_00006b84cda238931e60",
    orderId: "1651684161369",
    status: "PENDING",
    description: null,
    serviceProvider: "Vodafone Cash",
    transactionSource: "Test Integrator",
    transactionDate: 1651684162764,
    transactionType: "MOBILE_MONEY",
    reference: "233208418857",
    serviceName: "Vodafone Cash Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah ",
    payerContact: "233208418857",
    billerLogoUrl: "./static/images/logo/vf-cash.png",
    integratorLogoUrl: null,
    creditDebitFlag: "D",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Accept the service request successfully.",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: null,
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23320",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "59491c386763e0887e141625",
  },
  {
    amount: "20.0000",
    currency: "GHS",
    comment: "test",
    success: true,
    transactionId: "2721146548",
    receiptNo: "AG_20190305_00006b84cda238931e60",
    orderId: "1651669794635",
    status: "PENDING",
    description: null,
    serviceProvider: "Vodafone Cash",
    transactionSource: "Test Integrator",
    transactionDate: 1651669795903,
    transactionType: "MOBILE_MONEY",
    reference: "233208418857",
    serviceName: "Vodafone Cash Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah ",
    payerContact: "233208418857",
    billerLogoUrl: "./static/images/logo/vf-cash.png",
    integratorLogoUrl: null,
    creditDebitFlag: "D",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Accept the service request successfully.",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: null,
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23320",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "59491c386763e0887e141625",
  },
  {
    amount: "5.0000",
    currency: "GHS",
    comment: "test",
    success: true,
    transactionId: "5014638262",
    receiptNo: "AG_20190305_00006b84cda238931e60",
    orderId: "1651494539771",
    status: "PENDING",
    description: null,
    serviceProvider: "Vodafone Cash",
    transactionSource: "Test Integrator",
    transactionDate: 1651494539897,
    transactionType: "MOBILE_MONEY",
    reference: "233208418857",
    serviceName: "Vodafone Cash Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah ",
    payerContact: "233208418857",
    billerLogoUrl: "./static/images/logo/vf-cash.png",
    integratorLogoUrl: null,
    creditDebitFlag: "D",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Accept the service request successfully.",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: null,
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23320",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "59491c386763e0887e141625",
  },
  {
    amount: "20.0000",
    currency: "GHS",
    comment: "test",
    success: true,
    transactionId: "2857285613",
    receiptNo: "AG_20190305_00006b84cda238931e60",
    orderId: "1651494474318",
    status: "PENDING",
    description: null,
    serviceProvider: "Vodafone Cash",
    transactionSource: "Test Integrator",
    transactionDate: 1651494475417,
    transactionType: "MOBILE_MONEY",
    reference: "233208418857",
    serviceName: "Vodafone Cash Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah ",
    payerContact: "233208418857",
    billerLogoUrl: "./static/images/logo/vf-cash.png",
    integratorLogoUrl: null,
    creditDebitFlag: "D",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Accept the service request successfully.",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: null,
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23320",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "59491c386763e0887e141625",
  },
  {
    amount: "20.0000",
    currency: "GHS",
    comment: "test elevy",
    success: true,
    transactionId: "4450006937",
    receiptNo: "AG_20190305_00006b84cda238931e60",
    orderId: "1651491492088",
    status: "PENDING",
    description: null,
    serviceProvider: "Vodafone Cash",
    transactionSource: "Test Integrator",
    transactionDate: 1651491492249,
    transactionType: "MOBILE_MONEY",
    reference: "233208418857",
    serviceName: "Vodafone Cash Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah ",
    payerContact: "233208418857",
    billerLogoUrl: "./static/images/logo/vf-cash.png",
    integratorLogoUrl: null,
    creditDebitFlag: "D",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Accept the service request successfully.",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: null,
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23320",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "59491c386763e0887e141625",
  },
  {
    amount: "20.0000",
    currency: "GHS",
    comment: "test",
    success: true,
    transactionId: "0464763747",
    receiptNo: "AG_20190305_00006b84cda238931e60",
    orderId: "1651491111484",
    status: "PENDING",
    description: null,
    serviceProvider: "Vodafone Cash",
    transactionSource: "Test Integrator",
    transactionDate: 1651491111882,
    transactionType: "MOBILE_MONEY",
    reference: "233208418857",
    serviceName: "Vodafone Cash Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah ",
    payerContact: "233208418857",
    billerLogoUrl: "./static/images/logo/vf-cash.png",
    integratorLogoUrl: null,
    creditDebitFlag: "D",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Accept the service request successfully.",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: null,
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23320",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "59491c386763e0887e141625",
  },
  {
    amount: "5.0000",
    currency: "GHS",
    comment: "Paid 5.00 to MTN Airtime",
    success: true,
    transactionId: "5015478.0",
    receiptNo: null,
    orderId: "5835b586-539f-4e8d-a2ff-ddb41d46cc36",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646126238006,
    transactionType: "PAYMENT",
    reference: "0244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "0244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "423841.0",
    receiptNo: "",
    orderId: "8916a3ef-936e-427c-b6d7-11bbb9e65eb5",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646126237150,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-03",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "4245304.0",
    receiptNo: null,
    orderId: "20c4a9a5-3f81-4773-9d58-9ce9c557e0b9",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646126236978,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "5.0000",
    currency: "GHS",
    comment: "Paid 5.00 to MTN Airtime",
    success: true,
    transactionId: "2969563.0",
    receiptNo: null,
    orderId: "5ebc9998-6c77-4435-8b36-40f3dbab2073",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646125992179,
    transactionType: "PAYMENT",
    reference: "0244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "0244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "5345811.0",
    receiptNo: "",
    orderId: "f39a1d27-d22c-45b1-a790-97f23610834d",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646125991415,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-03",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "5936330.0",
    receiptNo: null,
    orderId: "07acedd0-244e-46b8-9085-be98028fa592",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646125991157,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6789199.0",
    receiptNo: null,
    orderId: "3efe7059-cdef-45f9-b1e6-5fc6b1d35c9b",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646125483136,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6401261.0",
    receiptNo: null,
    orderId: "49ec4b68-c870-459a-8d6f-b4c64547de08",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646125315243,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "562276.0",
    receiptNo: null,
    orderId: "026c5c8e-dfe1-4fdb-bd97-6c357da6f042",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646125236476,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "5.0000",
    currency: "GHS",
    comment: "Paid 5.00 to MTN Airtime",
    success: true,
    transactionId: "2687169.0",
    receiptNo: null,
    orderId: "dc8dd56d-db96-4e04-a9b8-239319be020c",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646124484451,
    transactionType: "PAYMENT",
    reference: "0244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "0244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "4216951.0",
    receiptNo: "",
    orderId: "59bf8a1f-365f-4711-ab02-11b7c3095f36",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646124404410,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-03",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: null,
    receiptNo: null,
    orderId: "19569d3b-b72b-4a3e-97c5-cf35237c497a",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646124377883,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "5192217.0",
    receiptNo: null,
    orderId: "3dcafbc9-be92-476d-aebc-0c250a96933a",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646124292249,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "3359515.0",
    receiptNo: null,
    orderId: "efa14a1f-9d06-41ff-8a96-11a628476bc4",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646123500278,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "4279071.0",
    receiptNo: null,
    orderId: "68a41487-3067-4f23-b94c-0d53c28acb22",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646123279477,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6345978.0",
    receiptNo: null,
    orderId: "572010a2-f27d-4e77-a704-704e315b1ccc",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646121788161,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "5.0000",
    currency: "GHS",
    comment: "Paid 5.00 to MTN Airtime",
    success: true,
    transactionId: "4074060.0",
    receiptNo: null,
    orderId: "31e9cebc-3462-4bd8-a029-6c153800e195",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646121769259,
    transactionType: "PAYMENT",
    reference: "0244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "0244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "5838419.0",
    receiptNo: "",
    orderId: "98cc32ba-e2f1-4e39-bb07-af2d42d9d0f9",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646121767114,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-03",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "1824304.0",
    receiptNo: null,
    orderId: "0f3a5704-fde5-4f3e-a15b-8a82a4bc895c",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646121762850,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "4794358.0",
    receiptNo: null,
    orderId: "7d02a1e0-5001-4119-aee1-1004370f4ebc",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079949333,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6507603.0",
    receiptNo: null,
    orderId: "d51f67a4-4e9d-4e6a-bcc5-b37e4d760b57",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079917486,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "1905554.0",
    receiptNo: null,
    orderId: "9bc951bf-fa25-41d1-840e-45078999c74e",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079870531,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6453884.0",
    receiptNo: null,
    orderId: "9e5b647e-9412-4921-be8c-b1d87fc723a2",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079741981,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "197152.0",
    receiptNo: null,
    orderId: "5e826f5e-0040-45fc-b90f-4399aabf9925",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079608209,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "1256242.0",
    receiptNo: null,
    orderId: "d9763aee-c2d2-4d65-a2eb-4ddb3a27938b",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079572949,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "361143.0",
    receiptNo: null,
    orderId: "535ac053-ce5b-42fb-adb6-3f3b188dfed8",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079348129,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6092386.0",
    receiptNo: null,
    orderId: "8026bad7-5c86-4432-bb10-40c87c254794",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646079021808,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "3937022.0",
    receiptNo: null,
    orderId: "cf3503c7-5c51-4cab-b30f-4214167a208c",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646078986170,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "4542074.0",
    receiptNo: null,
    orderId: "5a659e9c-6d88-4ca7-8fc7-4ddfeec508c5",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646078790882,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "1330982.0",
    receiptNo: null,
    orderId: "ffd4618b-cc2b-4e4f-b8c6-04a5424b9f29",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646078229698,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "3322129.0",
    receiptNo: null,
    orderId: "48beb495-ab7b-4989-9eeb-6b92ff579402",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646078114352,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6754548.0",
    receiptNo: null,
    orderId: "90014ad5-e97b-45c9-b5e1-b00a2730ebd2",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077781717,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "2655705.0",
    receiptNo: null,
    orderId: "154542bc-e168-4564-8051-a4d5a93b3d1c",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077761265,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "2474503.0",
    receiptNo: null,
    orderId: "54d7a6d5-2149-4103-99c3-1e52d634d1d5",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077686929,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "6674727.0",
    receiptNo: null,
    orderId: "4c3de1ef-3c5f-4e1a-9460-cade92ad5fe8",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077506851,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "5916719.0",
    receiptNo: null,
    orderId: "8f33478e-86cd-4cb6-bbca-f8bc40363857",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077420144,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "2955796.0",
    receiptNo: null,
    orderId: "3d7f6a5b-5610-4b0a-8fe5-dd6586b410a8",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077341504,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "4395998.0",
    receiptNo: null,
    orderId: "098bd11a-8141-4701-8270-b5e0e091eead",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646077259570,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "5.0000",
    currency: "GHS",
    comment: "Paid 5.00 to MTN Airtime",
    success: true,
    transactionId: "5398774.0",
    receiptNo: null,
    orderId: "f166b0fc-3352-4bf8-838c-e30d59d0a0d6",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646073821401,
    transactionType: "PAYMENT",
    reference: "0244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "0244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "3661667.0",
    receiptNo: "",
    orderId: "05ce5a55-031f-465f-bdf1-9922ca9daee4",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646073491444,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "4094423.0",
    receiptNo: "",
    orderId: "987e03d6-bd8d-4963-a9c1-c3931d67c5f3",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646073407224,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "2.0000",
    currency: "GHS",
    comment: "Testing institution code",
    success: true,
    transactionId: "1671221.0",
    receiptNo: "",
    orderId: "1b6586c1-de44-4407-a57d-4092167c4b78",
    status: "PENDING",
    description: null,
    serviceProvider: "MTN Money Billbox Payment OVA",
    transactionSource: "DO Acquiring",
    transactionDate: 1646073336121,
    transactionType: "MOBILE_MONEY",
    reference: "233244123456",
    serviceName: "MTN Mobile Money Merchant",
    user: "user",
    branch: "Failed Branch",
    payerName: "Test Customer",
    payerContact: "233244123456",
    billerLogoUrl: "./static/images/logo/mobile_money.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: "Debit request sent",
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-02",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "23324",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "57fb749a43bc3b802701d6d5",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: null,
    receiptNo: null,
    orderId: "12b53a33-7914-4160-8e7f-f079ee1e9e0a",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646073319572,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
  {
    amount: "4.0000",
    currency: "GHS",
    comment: "Testing card debit init",
    success: true,
    transactionId: "5137298.0",
    receiptNo: null,
    orderId: "cea0ffef-5429-4f6c-bc96-dd12206093ea",
    status: "PENDING",
    description: null,
    serviceProvider: "MiGS",
    transactionSource: "DO Acquiring",
    transactionDate: 1646073288349,
    transactionType: "PAYMENT",
    reference: "233244733999",
    serviceName: "MiGS Card Processing",
    user: "user",
    branch: "Failed Branch",
    payerName: "Kofi Asamoah",
    payerContact: "233244733999",
    billerLogoUrl: "./static/images/logo/visa-mastercard.png",
    integratorLogoUrl: null,
    creditDebitFlag: "C",
    canReverse: false,
    reversalPending: false,
    reversalReason: null,
    reversalRequestedBy: null,
    reversalEffectedBy: null,
    markupFee: "0.0000",
    customerName: null,
    paymentMode: null,
    referenceField: null,
    contactField: null,
    statusMessage: null,
    chequeNo: null,
    denominations: null,
    details: null,
    dateToSettle: "2022-03-01",
    settled: false,
    settledOn: null,
    exchangeRate: "1",
    serviceCode: "622",
    isRefund: false,
    refunded: false,
    providerError: false,
    serviceType: "5f493c6e74db9716f62a9f57",
  },
];
export const stats_old = {
  serviceCount: {
    GHS: 2,
  },
  transactionCount: {
    GHS: 10,
  },
  totals: {
    GHS: 132.0,
  },
  cr: {
    GHS: 132.0,
  },
  dr: {
    GHS: 0.0,
  },
};

export const userinfo = {
  sub: "a7ce2000-8ead-4569-959e-60f6c32b7d87",
  email_verified: true,
  name: "Test User",
  preferred_username: "user",
  given_name: "Test",
  family_name: "User",
  email: "user@test.com",
};

export const accounts = [
  {
    id: "a7ce2000-8ead-4569-345g-60f6c32b7d87",
    email_verified: true,
    name: "Test Account 1",
    preferred_username: "Account",
    given_name: "Test",
    family_name: "Account",
    email: "account1@test.com",
    default: true,
  },
  {
    id: "a7ce2000-8ead-4569-959e-60f6c32b7d87",
    email_verified: true,
    name: "Test Account 2",
    preferred_username: "account2",
    given_name: "Test",
    family_name: "Account 2",
    email: "account2@test.com",
    default: false,
  },
];

export const requestFilesListResponse = {
  status: true,
  result: {
    recordsTotal: 2,
    content: [
      {
        batchId: "a6b0221b-a1e9-482e-98ac-fd186c6a8138",
        featureName: "creditAccount",
        originalFileName: "send_money_sample.csv",
        fileType: "csv",
        numEntries: 2,
        mappedHeaders: {
          institutionCode: "institutionCode",
          accountNumber: "accountNumber",
          accountName: "accountName",
          senderName: "senderName",
          senderNumber: "senderNumber",
          amount: "amount",
          currency: "currency",
          transactionId: "transactionId",
          transactionDate: "transactionDate",
          narration: "narration",
        },
        amountField: "amount",
        totalValue: 2,
        dateUploaded: "2022-07-05T10:04:07.080+00:00",
        status: "PROCESSED",
        dateProcessed: "2022-07-12T17:17:06.709+00:00",
      },
      {
        batchId: "094e2e6e-4392-41cb-bb6d-113025de6541",
        featureName: "creditAccount",
        originalFileName: "send_money_sample.csv",
        fileType: "csv",
        numEntries: 2,
        mappedHeaders: {
          institutionCode: "institutionCode",
          accountNumber: "accountNumber",
          accountName: "accountName",
          senderName: "senderName",
          senderNumber: "senderNumber",
          amount: "amount",
          currency: "currency",
          transactionId: "transactionId",
          transactionDate: "transactionDate",
          narration: "narration",
        },
        amountField: "amount",
        totalValue: 2,
        dateUploaded: "2022-05-26T20:25:56.241+00:00",
        status: "PROCESSED",
        dateProcessed: null,
      },
      {
        batchId: "094e2e6e-4392-41cb-bb6d-113025de6543",
        featureName: "creditAccount",
        originalFileName: "send_money_sample.csv",
        fileType: "csv",
        numEntries: 2,
        mappedHeaders: {
          institutionCode: "institutionCode",
          accountNumber: "accountNumber",
          accountName: "accountName",
          senderName: "senderName",
          senderNumber: "senderNumber",
          amount: "amount",
          currency: "currency",
          transactionId: "transactionId",
          transactionDate: "transactionDate",
          narration: "narration",
        },
        amountField: "amount",
        totalValue: 2,
        dateUploaded: "2022-05-26T20:25:56.241+00:00",
        status: "PROCESSED",
        dateProcessed: null,
      },
      {
        batchId: "094e2e6e-4392-41cb-bb6d-113026he6541",
        featureName: "creditAccount",
        originalFileName: "send_money_sample.csv",
        fileType: "csv",
        numEntries: 2,
        mappedHeaders: {
          institutionCode: "institutionCode",
          accountNumber: "accountNumber",
          accountName: "accountName",
          senderName: "senderName",
          senderNumber: "senderNumber",
          amount: "amount",
          currency: "currency",
          transactionId: "transactionId",
          transactionDate: "transactionDate",
          narration: "narration",
        },
        amountField: "amount",
        totalValue: 2,
        dateUploaded: "2022-05-26T20:25:56.241+00:00",
        status: "PROCESSED",
        dateProcessed: null,
      },
      {
        batchId: "094e2e6e-4392-41cb-bb6d-113025de6547",
        featureName: "creditAccount",
        originalFileName: "send_money_sample.csv",
        fileType: "csv",
        numEntries: 2,
        mappedHeaders: {
          institutionCode: "institutionCode",
          accountNumber: "accountNumber",
          accountName: "accountName",
          senderName: "senderName",
          senderNumber: "senderNumber",
          amount: "amount",
          currency: "currency",
          transactionId: "transactionId",
          transactionDate: "transactionDate",
          narration: "narration",
        },
        amountField: "amount",
        totalValue: 2,
        dateUploaded: "2022-05-26T20:25:56.241+00:00",
        status: "PROCESSED",
        dateProcessed: null,
      },
      {
        batchId: "094e2e6e-4392-41cb-bb6d-113026he6542",
        featureName: "creditAccount",
        originalFileName: "send_money_sample.csv",
        fileType: "csv",
        numEntries: 2,
        mappedHeaders: {
          institutionCode: "institutionCode",
          accountNumber: "accountNumber",
          accountName: "accountName",
          senderName: "senderName",
          senderNumber: "senderNumber",
          amount: "amount",
          currency: "currency",
          transactionId: "transactionId",
          transactionDate: "transactionDate",
          narration: "narration",
        },
        amountField: "amount",
        totalValue: 2,
        dateUploaded: "2022-05-26T20:25:56.241+00:00",
        status: "PROCESSED",
        dateProcessed: null,
      },
    ],
  },
  message: "Files retrieved successfully",
  count: 2,
};

export const accountBalaceResponse = {
  status: true,
  data: "100658.50",
  count: 1,
  message: "Balance check successful",
};

export const recentActivity = [
  {
    action: "Transfer",
    amount: 1500,
    account_number: 2090888754336,
    bank_name: "Access Bank",
    date: new Date("10/24/2020 9:23:21 AM").getTime(),
  },
  {
    action: "Top Up",
    amount: 1500,
    account_number: 2090888754336,
    bank_name: "Stanbic Bank",
    date: new Date("10/24/2020 9:24:21 AM").getTime(),
  },
  {
    action: "Top Up",
    amount: 1500,
    account_number: 2090888754336,
    bank_name: "Ecobank Ghana",
    date: new Date("10/24/2020 9:25:21 AM").getTime(),
  },
  {
    action: "Transfer",
    amount: 1500,
    account_number: 2090888754336,
    bank_name: "GT Bank",
    date: new Date("10/24/2020 9:26:21 AM").getTime(),
  },
  {
    action: "Transfer",
    amount: 1500,
    account_number: 2090888754336,
    bank_name: "UMB",
    date: new Date("10/24/2020 9:27:21 AM").getTime(),
  },
];

export const stats = [
  {
    serviceCount: 8,
    currency: "GHS",
    transactionCount: 187,
    debitValue: 2780.57,
    creditValue: 2758.08,
    netValue: -22.490000000000236,
  },
  {
    serviceCount: 2,
    currency: "XOF",
    transactionCount: 4,
    debitValue: 310,
    creditValue: 105,
    netValue: -205,
  },
];

export const recentLogins = [
  // {
  //   status: "online",
  //   os: "Windows 11",
  //   browser: "Google Chrome",
  //   location: "Accra - Ghana",
  // },
  // {
  //   status: "offline",
  //   os: "macOS Monterey",
  //   browser: "Safari",
  //   location: "Keystone - South Dakota",
  // },
  {
    "id": "c0cbb864-a1c9-4663-9c42-d50a3e5dc40e",
    "ipAddress": "127.0.0.1",
    "startTime": 1667992199000,
    "lastActivity": 1667992199000
}
];

export const bankList = [
  {
    code: "absa-account-fi-service",
    name: "Absa",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: [{
      "name": "1% Integrator Service Fee",
      "minAmount": 0,
      "maxAmount": 0,
      "minCharge": 0,
      "maxCharge": 0,
      "fixedCharge": 0,
      "percentCharge": 0.008
  }]
  },
  {
    code: "access-account-fi-service",
    name: "Access Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "adb-account-fi-service",
    name: "ADB",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "arb-account-fi-service",
    name: "ARB Apex",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "bank-africa-account-fi-service",
    name: "Bank of Africa",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "cal-account-fi-service",
    name: "CAL Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "consolidated-bank-account-fi-service",
    name: "Consolidated Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "ecobank-account-fi-service",
    name: "Ecobank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "energy-account-fi-service",
    name: "Energy Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "fidelity-account-fi-service",
    name: "Fidelity Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "firstbank-nigeria-account-fi-service",
    name: "FBN",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "first-atlantic-account-fi-service",
    name: "First Atlantic Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "fnb-account-fi-service",
    name: "FNB Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "gcb-account-fi-service",
    name: "GCB",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "ghl-account-fi-service",
    name: "GHL Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "gmoney-account-fi-service",
    name: "GMoney",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "gt-account-fi-service",
    name: "GT Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "heritage-account-fi-service",
    name: "Heritage Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "nib-account-fi-service",
    name: "NIB",
    type: "BANK",
  },
  {
    code: "prudential-account-fi-service",
    name: "Prudential Bank",
    type: "BANK",
  },
  {
    code: "republic-account-fi-service",
    name: "Republic Bank",
    type: "BANK",
  },
  {
    code: "sahel-sahara-account-fi-service",
    name: "Sahel Sahara",
    type: "BANK",
  },
  {
    code: "sovereign-account-fi-service",
    name: "Sovereign Bank",
    type: "BANK",
  },
  {
    code: "stanbic-account-fi-service",
    name: "Stanbic Bank",
    type: "BANK",
  },
  {
    code: "standardchartered-account-fi-service",
    name: "Standard Chartered",
    type: "BANK",
  },
  {
    code: "uba-account-fi-service",
    name: "UBA",
    type: "BANK",
  },
  {
    code: "umb-account-fi-service",
    name: "UMB",
    type: "BANK",
  },
  {
    code: "zenith-account-fi-service",
    name: "Zenith Bank",
    type: "BANK",
  },
];

export const institutions = [
  {
    code: "slydepay",
    name: "Slydepay",
    type: "MOBILE_MONEY",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "mtn-money",
    name: "MTN Mobile Money",
    type: "MOBILE_MONEY",
    serviceCode: '10101',
    chargeModel: [{
      "name": "1% Integrator Service Fee",
      "minAmount": 0,
      "maxAmount": 0,
      "minCharge": 0,
      "maxCharge": 0,
      "fixedCharge": 0,
      "percentCharge": 0.003
  }]
  },
  {
    code: "vodafone-cash",
    name: "Vodafone Cash",
    type: "MOBILE_MONEY",
    serviceCode: '10101',
    chargeModel: [{
      "name": "1% Integrator Service Fee",
      "minAmount": 0,
      "maxAmount": 0,
      "minCharge": 0,
      "maxCharge": 0,
      "fixedCharge": 0,
      "percentCharge": 0.01
  }]
  },
  {
    code: "airteltigo-money",
    name: "AirtelTigo Money",
    type: "MOBILE_MONEY",
    serviceCode: '10101',
    chargeModel: [{
      "name": "1% Integrator Service Fee",
      "minAmount": 0,
      "maxAmount": 0,
      "minCharge": 0,
      "maxCharge": 0,
      "fixedCharge": 0,
      "percentCharge": 0.01
  }]
  },
  {
    code: "zeepay",
    name: "Zeepay",
    type: "MOBILE_MONEY",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "absa-account-fi-service",
    name: "Absa",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "access-account-fi-service",
    name: "Access Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "adb-account-fi-service",
    name: "ADB",
    type: "BANK",
  },
  {
    code: "arb-account-fi-service",
    name: "ARB Apex",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "bank-africa-account-fi-service",
    name: "Bank of Africa",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "cal-account-fi-service",
    name: "CAL Bank",
    type: "BANK",
    serviceCode: '10101',
    chargeModel: []
  },
  {
    code: "consolidated-bank-account-fi-service",
    name: "Consolidated Bank",
    type: "BANK",
  },
  {
    code: "ecobank-account-fi-service",
    name: "Ecobank",
    type: "BANK",
  },
  {
    code: "energy-account-fi-service",
    name: "Energy Bank",
    type: "BANK",
  },
  {
    code: "fidelity-account-fi-service",
    name: "Fidelity Bank",
    type: "BANK",
  },
  {
    code: "firstbank-nigeria-account-fi-service",
    name: "FBN",
    type: "BANK",
  },
  {
    code: "first-atlantic-account-fi-service",
    name: "First Atlantic Bank",
    type: "BANK",
  },
  {
    code: "fnb-account-fi-service",
    name: "FNB Bank",
    type: "BANK",
  },
  {
    code: "gcb-account-fi-service",
    name: "GCB",
    type: "BANK",
  },
  {
    code: "ghl-account-fi-service",
    name: "GHL Bank",
    type: "BANK",
  },
  {
    code: "gmoney-account-fi-service",
    name: "GMoney",
    type: "BANK",
  },
  {
    code: "gt-account-fi-service",
    name: "GT Bank",
    type: "BANK",
  },
  {
    code: "heritage-account-fi-service",
    name: "Heritage Bank",
    type: "BANK",
  },
  {
    code: "nib-account-fi-service",
    name: "NIB",
    type: "BANK",
  },
  {
    code: "prudential-account-fi-service",
    name: "Prudential Bank",
    type: "BANK",
  },
  {
    code: "republic-account-fi-service",
    name: "Republic Bank",
    type: "BANK",
  },
  {
    code: "sahel-sahara-account-fi-service",
    name: "Sahel Sahara",
    type: "BANK",
  },
  {
    code: "sovereign-account-fi-service",
    name: "Sovereign Bank",
    type: "BANK",
  },
  {
    code: "stanbic-account-fi-service",
    name: "Stanbic Bank",
    type: "BANK",
  },
  {
    code: "standardchartered-account-fi-service",
    name: "Standard Chartered",
    type: "BANK",
  },
  {
    code: "uba-account-fi-service",
    name: "UBA",
    type: "BANK",
  },
  {
    code: "umb-account-fi-service",
    name: "UMB",
    type: "BANK",
  },
  {
    code: "zenith-account-fi-service",
    name: "Zenith Bank",
    type: "BANK",
  },
];


import { Customers } from "redux/constants/Customers";

const initState = {
    data: [],
    loading: false,
  };

  const customers = (state = initState, action) => {
    switch (action.type) {
      case Customers.LOADING_CUSTOMERS_LIST:
        return {
          ...state,
          loading: action.payload,
        };
      case Customers.GET_CUSTOMERS_LIST:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default customers;
import { Transfers } from "redux/constants/Transfers";

const initState = {
  mappings: [],
  batchRequests: [],
  statRequests:[],
  loading: false,
  currentBatch: {}
};

const transfers = (state = initState, action) => {
  switch (action.type) {
    case Transfers.BULK.MAPPINGS.SET_MAPPINGS:
      return {
        ...state,
        mappings: {
          ...state?.mappings,
          fieldHeaderMapping: {
            ...state?.mappings?.fieldHeaderMapping,
            data: action.payload,
          },
        },
      };
    case Transfers.BULK.GET_MAPPINGS:
      return {
        ...state,
        mappings: {
          data: action.payload,
          // loading: state?.ma?.loading,
        },
      };
    case Transfers.BULK.MAPPINGS.SET_UPLOAD_FILE_HEADERS:
      return {
        ...state,
        batchRequests: {
          ...state?.batchRequests,
          batchId: action.payload.batchId,
        },
        mappings: {
          ...state?.mappings,
          batchId: action.payload.batchId,
          uploadFileHeaders: action.payload.headers,
        },
      };
    case Transfers.BULK.MAPPINGS.SETTING_HEADER_MAPPINGS_FOR_BATCH:
      return {
        ...state,
        mappings: {
          ...state?.mappings,
          fieldHeaderMapping: {
            ...state?.mappings?.fieldHeaderMapping,
            loading: action.payload,
          },
        },
      };
    case Transfers.BULK.BATCH_REQUESTS.LOADING:
      return {
        ...state,
        batchRequests: {
          ...state?.batchRequests,
          loading: action.payload,
        },
      };
    case Transfers.BULK.BATCH_REQUESTS.GET_REQUEST_DETAILS:
      return {
          ...state,
          currentBatch: {
            ...state?.currentBatch,
            currentBatch: {
              loading: state?.batchRequests?.currentBatch?.loading,
              data: action.payload || {}
            },
          },
      };
    case Transfers.BULK.BATCH_REQUESTS.GET_REQUESTS_IN_A_BATCH:
      return {
        ...state,
        batchRequests: {
          ...state?.batchRequests,
          selectedBatch: {
            loading: state?.batchRequests?.selectedBatch?.loading,
            data: action.payload?.content || [],
            recordsTotal: action.payload?.recordsTotal || 0
          },
        },
      };
    case Transfers.BULK.BATCH_REQUESTS.LOADING_REQUESTS_IN_BATCH:
      return {
        ...state,
        batchRequests: {
          ...state?.batchRequests,
          selectedBatch: {
            ...state?.batchRequests?.selectedBatch,
            loading: action.payload,
          },
        },
      };
    case Transfers.BULK.BATCH_REQUESTS.GET_BATCH_REQUESTS:
      return {
        ...state,
        batchRequests: {
          ...state?.batchRequests,
          data: action.payload,
        },
      };
    case Transfers.SINGLE.BANK_LIST.LOADING:
      return {
        ...state,
        bankList: {
          ...state?.bankList,
          loading: action.payload,
        },
      };
      case Transfers.BULK.BATCH_REQUESTS.GET_STATS_IN_BATCH:
    return {
      ...state,
      statRequests: {
        ...state?.statRequests,
        selectedBatch: {
          loading: state?.statRequests?.selectedBatch?.loading,
          data: action.payload || [],
        },
      },
    };
  case Transfers.BULK.BATCH_REQUESTS.LOADING_STATS_IN_BATCH:
    return {
      ...state,
      statRequests: {
        ...state?.statRequests,
        selectedBatch: {
          ...state?.statRequests?.selectedBatch,
          loading: action.payload,
        },
      },
    };
    case Transfers.SINGLE.BANK_LIST.GET_BANK_LIST:
      return {
        ...state,
        bankList: {
          ...state?.bankList,
          data: action.payload,
        },
      };
    case Transfers.SINGLE.NETWORK_LIST.LOADING:
      return {
        ...state,
        networkList: {
          ...state?.networkList,
          loading: action.payload,
        },
      };
    case Transfers.SINGLE.NETWORK_LIST.GET_NETWORK_LIST:
      return {
        ...state,
        networkList: {
          ...state?.networkList,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default transfers;
